import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MerchantPartnershipTerms({
  children
}) {
  return <LegalDoc title="Merchant Partnership Agreement Terms and Conditions">
      {children}
    </LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`The below terms ("Terms of Service”) set out the rights and obligations of Fidel and Merchant in relation to the Merchant Offers Platform and use of any Publisher Channels. `}</strong></p>
    <p>{`Merchant provides certain benefits to customers via offers that may be redeemed when making purchases with registered Payment Cards from Merchant, such as Merchant Content. These Terms and Conditions (Terms & Conditions) set out the terms on which Fidel API will provide the Fidel API Services to Merchant.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Interpretation`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In the Terms and Conditions, save where the context requires otherwise, the following words and expressions have the following meanings:`}
            <ol type="a">
   <li><b>"Agreement"</b> means the agreement between Merchant and Fidel API comprising the Booking Form, these Terms and Conditions and, in relation to a particular offer, the relevant Insertion Order. To the extent any inconsistency between the terms of the Booking Form, Terms and Conditions herein, and the relevant Insertion Order, the terms of the Insertion Order shall prevail, then these Terms and Conditions, and then the Booking Form;</li>
   <li><b>"American Express"</b> means American Express Payment Services Limited and its successors and its affiliated companies;</li>
   <li><b>"Booking Form"</b> means the signed (whether electronically or otherwise) booking form relating to these Terms & Conditions which, amongst other things, identifies the Merchant and sets out the Commencement Date;</li>
   <li><b>"Commencement Date"</b> means the date set out in the Booking Form;</li>
   <li><b>"Commission"</b> means the commission payable by Merchant to Fidel API as set out in any relevant Insertion Order;</li>
   <li><b>"Confidential Information"</b> means information which is identified as confidential or proprietary by either party or by the nature of which is clearly confidential or proprietary;</li>
   <li><b>"Fidel API"</b> has the meaning set out in the Booking Form;</li>
   <li><b>"Fidel API Fees"</b> means the amount set out on the Booking Form;</li>
   <li><b>"Fidel API Services"</b> means providing access to Merchant to the Offers Marketplace and publishing approved Merchant Content on the Offers Marketplace for the purposes of making such Merchant Content available to Publishers;</li>
   <li><b>"Fees"</b> means the Commission and the Fidel API Fees;</li>
   <li><b>"Information Security Incident"</b> means any actual or reasonably suspected: (a) unauthorized use of, or unauthorized access to, any of Merchant’s computers or networks; (b) damage to, or inability to access, any of Merchant’s computers or networks due to a malicious use, attack or exploit of such computers; (c) unauthorized access to, theft of, or loss of Confidential Information, including, but not limited to, Reconciliation Data; (d) unauthorized use of Confidential Information, including, but not limited to, Reconciliation Data, for purposes of actual, reasonably suspected, or attempted theft, fraud, identity theft, or other misuse; (e) breach of, or transmission of malicious code or a Virus to any Merchant’s computers or networks arising from, in whole or part, an act, error, or omission of Merchant, or third parties acting on behalf of Merchant; or (f) unauthorized disclosure of Confidential Information, including, unauthorized disclosure of Confidential Information, including, but not limited to, Reconciliation Data;</li>
   <li><b>"Insertion Order"</b> or “IO” means a document executed between the Parties setting out the campaign information required for Fidel API to provide the Fidel API Services subject to the terms of this Agreement;</li>
   <li><b>"Loyalty Program"</b> means a rewards service or a card-linked offers program;</li>
   <li><b>"Mastercard"</b> means Mastercard Europe SA and its affiliated companies;</li>
   <li><b>"Merchant"</b> means the person or entity which operates or controls the point of sale equipment relating to a Transaction;</li>
   <li><b>"Merchant Content"</b> means any content provided or produced by a Merchant relating to a card-linked offer, including the terms and conditions applicable to the offer;</li>
   <li><b>"Merchant Data"</b> means a Merchant's MIDs, the Merchant's name, the Merchant's outlet addresses, and any other information required to help identify the Merchant;</li>
   <li><b>"Merchant Email"</b> has the meaning specified in clause 5.1;</li>
   <li><b>"MID"</b> means the Merchant ID;</li>
   <li><b>"Offers Marketplace"</b> means the online platform operated by Fidel API in respect of which Merchant may upload Merchant Content and which Publishers can use to select the Merchant Content they wish to advertise in connection with their respective Loyalty Programs;</li>
   <li><b>"Payment Card"</b> means a credit or debit card issued by a Payment Provider;</li>
   <li><b>"Payment Provider"</b> means a payment provider of Merchant authorized in accordance with clause 3 (<i>Consent to monitor payment activity</i>) to provide Transaction Data to Fidel API for example Visa, Mastercard, or American Express</li>
   <li><b>"Permitted Use"</b> has the meaning set out in clause 4.1;</li>
   <li><b>"Publisher"</b> means each operator of a Loyalty Program in respect of which Fidel API has granted access to the Offers Marketplace;</li>
   <li><b>"Reconciliation Data"</b> means, in relation to a Transaction, amount paid, currency, date and time of the Transaction, MID, Fidel API brand name, Fidel API offer amount and Fidel API-generated transaction ID and name;</li>
   <li><b>"Restricted Industries"</b> means the industries set out in Schedule 1 (<i>Restricted Industries</i>);</li>
   <li><b>"Terms and Conditions"</b> means these terms and conditions;</li>
   <li><b>"Territory"</b> has the meaning set out in the Insertion Order;</li>
   <li><b>"Transaction"</b> means a qualifying point of sale purchase with a Merchant made by a User which results in the User validly redeeming Merchant Content in accordance with their terms as made available by a Publisher;</li>
   <li><b>"Transaction Data"</b> means the following data set supplied via a Payment Provider relating to a Transaction: auth code, amount paid, date and time of the Transaction, MID and Fidel API-generated transaction ID;</li>
   <li><b>"User"</b> means an individual who has enrolled their Payment Card(s) with a Publisher in order to receive rewards in connection with the Publisher’s Loyalty Programs;</li>
   <li><b>"Violating Content"</b> has the meaning given in clause 5.3;</li>
   <li><b>"Virus"</b> means any thing or device (including any software, code, file or program) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any program or data, including the reliability of any program or data (whether by re-arranging, altering or erasing the program or data in whole or part or otherwise); or adversely affect the user experience, including worms, Trojan horses, viruses and other similar things or devices; and</li>
   <li><b>"Visa"</b> means Visa Europe Services Inc. and its affiliated companies.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Purpose and Duration`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API will provide the Fidel API Services to Merchant in accordance with the terms of this Agreement.`}</li>
          <li parentName="ol">{`If Merchant elects to provide Merchant Content for the Offers Marketplace, it will do so in accordance with the requirements of the Fidel API Services and the terms of this Agreement.`}</li>
          <li parentName="ol">{`The Agreement shall start on the Commencement Date in the Booking Form and continue until termination in accordance with the terms of this agreement or expiry.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Consent to monitor payment activity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant shall`}
            <ol type="a">
   <li>provide to Fidel API the Merchant Data and any other data necessary to help Payment Providers to identify Merchants, such as Merchant names and branch addresses;</li>
   <li>accept all eligible Payment Cards for payments (or part-payments) as described in Merchant Content in its retail outlets and (if applicable) online through its website(s) and mobile application(s) for all goods and/or services, provided that Merchant shall not be in breach of this clause if it refuses to accept a Payment Card in the event of a reasonable suspicion of fraud or other unlawful activity; and</li>
   <li>provide all benefits and meet all obligations in relation to the Merchant Content and the Loyalty Program.</li>
            </ol>
          </li>
          <li parentName="ol">{`Merchant hereby consents for the Payment Providers to monitor Transactions made via Merchant’s payment card terminals and to access the corresponding Transaction Data, for the sole purpose of enabling the Payment Providers to:`}
            <ol type="a">
   <li>identify, track and record Transactions;</li>
   <li>provide the Transaction Data to Fidel API and the Publishers; and</li>
   <li>validate and, where necessary, update the accuracy of their records.</li>
            </ol>
          </li>
          <li parentName="ol">{`Merchant hereby agrees, consents to, and authorizes Fidel API to obtain data about the Transactions from any third party as Fidel API may reasonably require for provision of the Fidel API Services. Merchant hereby grants to Fidel API a license to access the Merchant Data, and to transfer the Merchant Data to the Payment Providers for the sole purpose of enabling the Payment Providers to:`}
            <ol type="a">
   <li>identify, track and record Transactions;</li>
   <li>provide the Transaction Data to Fidel API and the Publishers; and</li>
   <li>validate and, where necessary, update the accuracy of their records.</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API shall provide the Merchant with:`}
            <ol type="a">
   <li>a description of all information about the Merchant that Fidel API collects, including an indication of all potential sources of such data;</li>
   <li>identity of the collector of the Merchant's information;</li>
   <li>purpose for which the Merchant's information will be used;</li>
   <li>to whom the Merchant's information (or portions thereof) may be disclosed, including the recipients or categories of recipients;</li>
   <li>whether providing Merchant's information is voluntary or obligatory; and</li>
   <li>right of access and right to correct Merchant's information.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Merchant’s restrictions on the use of Reconciliation Data`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API grants to Merchant a non-exclusive, non-transferable right to use the Reconciliation Data for the sole purpose of substantiating and cross-checking fees and pay the Commission and generally comply with its obligations under clause 11 (the “`}<strong parentName="li">{`Permitted Use`}</strong>{`”), and for no other purpose. In particular, Merchant shall not use, and shall not permit any other person to use, the Reconciliation Data for any marketing, internal modelling, or data analytics purposes, including behavioral advertising (such as identifying segments of a database of Users that exhibit certain shopping behavior at certain retailers, or certain types of retailer, and provide a segment with an offer or other relevant message). Merchant shall not use the Reconciliation Data (whether by itself or with any other information) in a way that would enable a natural person to be identified, directly or indirectly, from the data.`}</li>
          <li parentName="ol">{`Fidel API shall provide the Reconciliation Data to Merchant through access to a report.`}</li>
          <li parentName="ol">{`In addition to the obligations and restrictions set out in clause 4.1 above, Merchant agrees in respect of any Reconciliation Data that it receives that Merchant will:`}
            <ol type="a">
  <li>retain the Reconciliation Data only for so long as is reasonably necessary for the Permitted Use and in any event no longer than two (2) years from the date of the relevant Transaction unless required by law to retain for longer period of time;</li>
  <li>not share, transfer, or disclose the Reconciliation Data to any third parties, or otherwise cause Reconciliation Data to be disclosed to any third parties (including via assignment or subcontracting) without the prior written consent of Fidel API unless required to properly perform this Agreement within the Permitted Use. Merchant shall at all times remain liable for all of its obligations under this Agreement, and will be liable for the acts and omissions of any third party with which it shares, or to which it gives access to, Reconciliation Data as if they were Merchant’s own acts and omissions, and will ensure that all such third parties comply with any security obligations of Merchant set out in this Agreement; and</li>
  <li>not use the Reconciliation Data (or any portion thereof) to develop, derive, or create, directly or indirectly, a personal identification number, individual reference number, or any "data integration" system which Merchant offers or makes available to third parties.</li>
            </ol>
          </li>
          <li parentName="ol">{`The Parties acknowledge and agree that Fidel API has the right to obtain equitable relief regarding Merchant’s use or threatened use of the Reconciliation Data contrary to the Permitted Use. Merchant shall provide to Fidel API and to the Payment Providers, and to each of their auditors and other advisers and representatives as Fidel API may from time to time designate in writing, (giving at least fourteen (14) days' prior written notice) access at all reasonable times (and in the case of regulators, at any time required by such regulators) to any facility in which Merchant performs this Agreement, Merchant's employees, and data and records of Merchant, for the purpose of performing audits and inspections of Merchant in order to verify Merchant's compliance with the terms and conditions of this Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Merchant's obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant shall provide an email address for the purpose of clause 6.3 (“`}<strong parentName="li">{`Merchant Email`}</strong>{`”) and shall provide the Merchant Content to Fidel API in a form specified from time to time by Fidel API. Merchant represents and warrants that Merchant is the owner or licensee of all Merchant Content.`}</li>
          <li parentName="ol">{`Once the Merchant Content has been made available to Fidel API via the Insertion Order, Merchant shall not change such Merchant Content, without at least 14 days’ written notice of changes of Merchant Content, and allow Users to redeem such Merchant Content in the form and manner as such Merchant Content was originally made available to the Offers Marketplace for the duration of this notice period and will honor the terms of any existing Transactions already made under the original Merchant Content.`}</li>
          <li parentName="ol">{`Merchant shall accept all eligible Payment Cards permitted for use as specified in the Merchant Content in its retail outlets and (if applicable) online through its website(s) and mobile application(s). For the avoidance of doubt, Merchant shall not be in breach of this clause 5.3 if it refuses to accept a Payment Card from a User in case of reasonable suspicion of fraud or other unlawful activity.`}</li>
          <li parentName="ol">{`Merchant must not provide Merchant Content which:`}
            <ol type="a">
    <li>is obscene, misleading, dishonest or otherwise in violation of applicable advertising regulations, standards, industry codes or regulatory guidelines; or</li>
    <li>relates to any of the Restricted Industries,</li>
            </ol>
            <ol type="none">
  <li>(together, the <b>"Violating Content"</b>).</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API may refuse to upload or remove Violating Content at any time acting in its sole discretion.`}</li>
          <li parentName="ol">{`Merchant:`}
            <ol type="a">
  <li>must provide true, accurate, and complete information in the Booking Form, and any relevant Insertion Order, and acknowledges and agrees that, as between Merchant and Fidel API, Merchant is wholly responsible for its obligations under the Merchant Content;</li>
  <li>must comply with all applicable laws and regulations with respect to its use of the Reconciliation Data and its activities under the Agreement;</li>
  <li>must use the Reconciliation Data only in accordance with the terms of the Agreement;</li>
  <li>must obtain and shall maintain all necessary licenses, consents, permits and permissions necessary:
  <ol>
    <li>for it to provide any Merchant Content and make it available to Fidel API and Publishers under this Agreement; and</li>
    <li>for Fidel API to perform its obligations under the terms of the Agreement;</li>
  </ol>
  </li>
  <li>must comply with requests by Users to redeem Merchant Content in accordance with the terms and conditions relating to such Merchant Content; and</li>
  <li>shall update MIDs and other Merchant Data provided to Fidel API promptly after becoming aware of a change and inform Fidel API accordingly, and Merchant consents that Fidel API may provide Payment Providers with MIDs and any other Merchant Data provided by Fidel API to Payment Providers to help them identify Merchant, such as Merchant name and branch addresses.</li>
            </ol>
          </li>
          <li parentName="ol">{`Merchant agrees that failure to comply with this clause constitutes a material breach of this Agreement, and may result in Fidel API taking all or any of the following actions:`}
            <ol type="a">
  <li>removing any Merchant Content from the Offers Marketplace, in which case, Fidel API shall notify Merchant of the removal of such Merchant Content and Fidel API shall, at Merchant’s cost, take such steps as necessary to procure that any Publisher, who has made such Merchant Content available to its Users, is contacted to remove such Merchant Content; or</li>
  <li>any other action Fidel API in its sole discretion considers to be necessary.</li>
            </ol>
          </li>
          <li parentName="ol">{`Merchant shall indemnify and defend Fidel API, the Payment Providers, and each of their officers, directors, employees, agents and contractors from and against any and all losses, damages, claims, liabilities or expenses (including reasonable lawyers' fees) arising out of any claim brought by a third party (including Publishers) relating to the Merchant Content (except to the extent caused by Fidel API's negligence).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Advertisement of Merchant Content`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant hereby appoints Fidel API as its agent to enter into agreements with Publishers on Merchant’s behalf for the advertisement of the Merchant Content on the terms set out in the Merchant Content.`}</li>
          <li parentName="ol">{`Fidel API shall publish the Merchant Content on the Offers Marketplace in order that Publishers can review Merchant Content and elect whether to make such Merchant Content available to their respective Users in connection with the operation of their respective Loyalty Programs. This is provided that Fidel API shall not be obliged to publish any Merchant Content on the Offers Marketplace to the extent that a Payment Provider has prohibited the use of such Merchant Content by Fidel API or Fidel API reasonably considers that a Payment Provider is likely to object to such Merchant Content on the basis that it will or is reasonably likely to damage such Payment Provider's reputation.`}</li>
          <li parentName="ol">{`At the time when a Publisher elects to use Merchant Content, Fidel API will contact Merchant using the Merchant Email seeking approval for such Publisher to use such Merchant Content and Fidel API shall not permit such Publisher to use such Merchant Content until Merchant has provided approval (via the Merchant Email).`}</li>
          <li parentName="ol">{`At the time when a Publisher elects to use such Merchant Content, Merchant acknowledges that Merchant, acting through Fidel API, is entering into a contract with the Publisher as a principal on the terms set out in the Merchant Content for the use of such Merchant Content, and as between Fidel API and Merchant, Merchant is solely responsible for paying all Fees relating to this Merchant Content and related Transactions in accordance with such terms and this Agreement.`}</li>
          <li parentName="ol">{`Fidel API shall have no liability whatsoever (save to the extent such claims are caused by Fidel API’s negligence) under any agreement entered into with Publishers as agent on Merchant’s behalf and Merchant shall indemnify and defend Fidel API, its agents and contractors, from and against any and all losses, damages, claims, liabilities, and expenses (including reasonable lawyers' fees) arising out of any claim by the Publisher arising from Merchant’s breach of such contract or use of the Merchant Content.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Intellectual property rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`As between Merchant and Fidel API, Fidel API is the owner or licensee of all intellectual property rights in the Reconciliation Data. These works are protected by copyright and other laws and treaties around the world. All such rights are reserved.`}</li>
          <li parentName="ol">{`As between Merchant and Fidel API, Merchant is the owner or licensee of all intellectual property rights in the Merchant Content, and those works may be protected by copyright and other laws and treaties around the world and Merchant hereby grants to Fidel API a non-exclusive, royalty-free and worldwide license to use such intellectual property rights (including the trademarks of Merchant) for the purposes of making available the Merchant Content to Publishers via the Offers Marketplace as contemplated by this Agreement.`}</li>
          <li parentName="ol">{`Merchant shall indemnify and defend Fidel API, its agents and contractors, from and against any and all losses, damages, claims, liabilities, and expenses (including reasonable legal fees) arising out of any claim brought by a third party relating to Merchant Content (except to the extent caused by Fidel API's negligence).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Publicity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant hereby agrees to permit Fidel API to use Merchant’s name, logo, and related trademarks in any of Fidel API’s publicity or marketing materials (whether in printed or electronic form) for the purpose of highlighting the relationship between Merchant and Fidel API. Such approval shall be deemed to include the grant to Fidel API of such rights as are necessary to use Merchant’s name, logo, and related trademarks for the purposes of this clause 8.1.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Information security`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant shall have in place appropriate technical and organizational measures to keep the Reconciliation Data secure, and to protect against unauthorized or unlawful disclosure of, or access to, or accidental loss or destruction of, or damage to, any Reconciliation Data.`}</li>
          <li parentName="ol">{`Merchant must promptly notify Fidel API in writing upon becoming aware of any actual or suspected Information Security Incident.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Confidential Information`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Each party may be given access to Confidential Information from the other party in order to perform its obligations under the Agreement. A party's Confidential Information shall not be deemed to include information that:`}
  <ol type="a">
    <li>is or becomes publicly known other than through any act or omission of the receiving party;</li>
    <li>was in the other party's lawful possession before the disclosure;</li>
    <li>is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</li>
    <li>is independently developed by the receiving party, which independent development can be shown by written evidence; or</li>
    <li>is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</li>
  </ol>
          </li>
          <li parentName="ol">{`Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available for use for any purpose other than as needed to perform the terms of the Agreement during the Term and for so long as the Confidential Information remains confidential.`}</li>
          <li parentName="ol">{`Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of the Agreement.`}</li>
          <li parentName="ol">{`Each party shall limit dissemination of Confidential Information of the other party to persons within its own organization; to third parties who are bound by written confidentiality obligations consistent with this clause 10; and all on a “need-to-know” basis.`}</li>
          <li parentName="ol">{`Each party shall use the same degree of care to protect the Confidential Information as it uses to protect its own most highly confidential information, but in no circumstances less than reasonable care. In the event the receiving party receives a court order or other validly issued administrative or judicial process demanding the Confidential Information, or to the extent required to be disclosed by any applicable laws or regulations by a party as a publicly traded company, the receiving party will be permitted to disclose Confidential Information to the minimum extent necessary to comply with such legal obligations. The receiving party will, to the extent not prohibited by law, give the disclosing party prompt written notice of any disclosure that, in the opinion of its counsel, appears to be required by law, so that the disclosing party, at its sole cost and expense, may assert any defenses to disclosure that may be available and seek an injunction or other protective relief.`}</li>
          <li parentName="ol">{`The parties agree that in the event of any breach of the confidentiality obligations described herein, the non-breaching party shall be entitled to seek immediate injunctive relief for such breach in addition to any other right or remedy that the non-breaching party may have at law or in equity.`}</li>
          <li parentName="ol">{`At the time of expiration or termination of this Agreement, as applicable, or upon the other party’s request, each party agrees to promptly return or destroy any Confidential Information provided by the other party in connection with this Agreement.`}</li>
          <li parentName="ol">{`Each party acknowledges and agrees that, as between the parties, Confidential Information disclosed by the disclosing party to the receiving party will at all times remain the property of the disclosing party. No license under any trade secrets, copyrights, or other rights is granted by any disclosure of Confidential Information under this Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Fees`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Reconciliation and Payment`}
            <ol type="a">
  <li>Fidel API shall provide Reconciliation Data to Merchant upon request only for the purpose of ascertaining the Commission payable by the Merchant in respect of a Transaction.</li>
  <li>Outstanding Fees shall be invoiced to Merchant at the end of each month. Merchant shall pay any invoice to Fidel API within thirty (30) days from the receipt of such invoice to an account as designated by Fidel API. All amounts specified are exclusive of all applicable taxes (such as VAT) unless specified otherwise.</li>
  <li>In the event of a failure by Merchant to fulfill its obligations as outlined in Clause 11.1.2, resulting in non-payment or delayed payment, then, without limiting Fidel API’s remedies under Clause 12, Merchant shall pay interest on any unpaid amounts from the due date until payment of the overdue sum, whether before or after judgment. The interest shall be calculated at a rate of 3% above the prevailing Bank of England rate, compounded daily, and Fidel API may exercise all rights and remedies available to it under the Agreement or any succeeding agreement.</li>
  <li>Merchant shall be solely responsible for the payment of all fees incurred in connection with Transactions made by Users with the Merchant, including Commissions, and shall indemnify and hold Fidel API harmless against any and all losses, damages, claims, liabilities or expenses (including reasonable lawyers' fees) arising out of any claim brought by a third party in connection with Merchant’s failure to pay the Fees in accordance with this clause 11.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Suspension and termination`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Either party may terminate the Agreement at any time on written notice to the other if the other:`}
            <ol type="a">
  <li>is in material or persistent breach of any of the terms of the Agreement and either that breach is incapable of remedy, or the other party fails to remedy that breach within 30 days after receiving written notice requiring it to remedy that breach;</li>
  <li>is unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986), or becomes insolvent, or is subject to an order or a resolution for its liquidation, administration, winding-up or dissolution (otherwise than for the purposes of a solvent amalgamation or reconstruction), or has an administrative or other receiver, manager, trustee, liquidator, administrator or similar officer appointed over all or any substantial part of its assets, or enters into or proposes any composition or arrangement with its creditors generally, or is subject to any analogous event or proceeding in any applicable jurisdiction; or</li>
  <li>is in breach of applicable law.</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API may terminate this Agreement for convenience on 14 days’ written notice.`}</li>
          <li parentName="ol">{`Merchant may terminate this Agreement for convenience on 30 days’ written notice, provided no active Insertion Order in effect. For the avoidance of doubt, in the event an Insertion Order is in effect at the time of notice, termination will take effect 30 days’ after the Insertion Order expires.`}</li>
          <li parentName="ol">{`Upon termination of the Agreement:`}
            <ol type="a">
  <li>Fidel API shall remove any Merchant Content from the Offers Marketplace; and</li>
  <li>subject to the sole discretion of Fidel API, Fidel API shall continue to provide Reconciliation Data until such time as the Merchant Content is no longer being made available by Publishers, provided that all obligations in this Agreement relating to Merchant’s use of the Reconciliation Data and payment of Fees shall continue to apply during this time.</li>
            </ol>
          </li>
          <li parentName="ol">{`The accrued rights of the parties as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination shall not be affected or prejudiced.`}</li>
          <li parentName="ol">{`Fidel API may terminate the Agreement by written notice with immediate effect in the event that a Payment Provider is unwilling or unable to provide the Transaction Data to Fidel API. If this clause is exercised, Fidel API agrees to provide Merchant with written proof that Payment Provider is unwilling or unable to provide the Transaction Data.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Exclusion of warranties`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Merchant acknowledges and agrees that the Reconciliation Data is provided by Fidel API in good faith on an "AS IS" basis. Fidel API gives no representations or warranties that the Reconciliation Data is reliable, accurate, or complete, or that Merchant’s access to the Reconciliation Data will be uninterrupted, timely or secure. Fidel API does not accept responsibility for loss suffered as a result of reliance on the accuracy or currency of the Reconciliation Data.`}</li>
          <li parentName="ol">{`Without limiting the effect of clause 13.1, Fidel API does not warrant, and neither Fidel API nor any Payment Provider shall have any liability in respect of, the following:`}
            <ol type="a">
  <li>that access to the Reconciliation Data will be error-free or free from interruption; or</li>
  <li>2. the Reconciliation Data is accurate, complete, reliable, secure, useful, fit for purpose, or timely.</li>
            </ol>
          </li>
          <li parentName="ol">{`Merchant acknowledges that Fidel API requires access to the Reconciliation Data in order to provide such data to the Merchant. Merchant agrees that Fidel API is not responsible for the non-availability of any such data source.`}</li>
          <li parentName="ol">{`Merchant hereby acknowledges and agrees that the availability of the Loyalty Programs depends on the willingness of Publishers to select Merchant Content or Users willingness to participate in the Loyalty Programs and make purchases from the Merchant. As a result, Fidel API provides no guarantees in respect of:`}
            <ol type="a">
  <li>the availability of any Publisher Loyalty Programs during the term of this Agreement;</li>
  <li>the types of Payment Cards that may be registered by Users and used to complete Transactions with Merchant; and/or</li>
            </ol>
          </li>
          <li parentName="ol">{`any volumes of sales achieved by the Merchant in connection with the Loyalty Programs. Fidel API warrants that it has and will maintain all necessary licenses, consents, and permissions necessary for the performance of its obligations under the Agreement.`}</li>
          <li parentName="ol">{`Merchant warrants that it has and will maintain compliance with all laws and maintain all necessary licenses, consents, and permissions necessary for the performance of its obligations under the Agreement and any Merchant Content for the Offers Marketplace.`}</li>
          <li parentName="ol">{`Except as expressly and specifically provided for in the Agreement:`}
            <ol type="a">
  <li>Merchant assumes sole responsibility for any results obtained from the use of the Reconciliation Data and for any conclusions drawn or taken from such use and it relies on the results obtained from such information at its own risk; and</li>
  <li>all representations, warranties, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by law, excluded from the Agreement.</li>
            </ol>
          </li>
          <li parentName="ol">{`Except as expressly provided in this Agreement, there are no conditions, warranties or other terms binding on the parties with respect to the rights and obligations contemplated by this Agreement. Any condition, warranty or other term in this regard that might otherwise be implied or incorporated into this Agreement, whether by statute, common law or otherwise, is, to the extent that it is lawful to do so, excluded by this Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Third-Party Commercial Relationships`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Each party agrees that it has no right to bind the other party in contract or otherwise in relation to any customers of either party (including, but not limited to Users), and it shall not represent that it has such right.`}</li>
          <li parentName="ol">{`For the avoidance of doubt, Fidel API will not be a party to any contractual relationship that may arise between (i) the Users and Merchant; or (ii) Merchant and Publisher, in connection with the Transaction (or any other relationship), and Fidel API shall have no rights and/or liabilities in respect of any such relationship between (i) Merchant and User; or (ii) Merchant and Publisher.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Liability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Subject to clause 14.2, neither party will be liable to the other whether in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise for any loss of income or revenue; loss of business; loss of profits; loss of anticipated savings; loss of data (other than loss of data caused by a breach of the Agreement); or any indirect, consequential or special damages, costs or expenses.`}</li>
          <li parentName="ol">{`Nothing in the Agreement excludes or limits either party’s liability for death or personal injury caused by its negligence or for fraud or fraudulent misrepresentation or for any indemnity given by Merchant under the Agreement.`}</li>
          <li parentName="ol">{`Subject to clause 15.2 and other than as expressly stated otherwise in this Agreement, Fidel API’s total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise arising in connection with the performance or contemplated performance of the Agreement shall in all circumstances be limited to one thousand pounds (£1,000).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Notices`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`All notices given by Merchant to Fidel API must be given to `}<a parentName="li" {...{
              "href": "mailto:merchant.partnerships@fidel.uk"
            }}>{`merchant.partnerships@fidel.uk`}</a>{`. Fidel API may give notice to Merchant at either the e-mail or postal address Merchant provides to Fidel API, or any other way Fidel API deems appropriate. Notice will be deemed received and properly served 24 hours after an e-mail is sent or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail that such e-mail was sent to the specified e-mail address of the addressee.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Transfer of rights and obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Neither party may transfer, assign, charge or otherwise deal in the Agreement, or any of its rights or obligations arising under the Agreement, without the other party’s prior written consent.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Events outside a party's control`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No party shall be liable to the other for any delay or non-performance of its obligations under the Agreement arising from any cause beyond its control including, without limitation, any of the following: telecommunications failure, internet failure, act of God, governmental act, war, fire, flood, explosion or civil commotion.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Waiver`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No forbearance or delay by either party in enforcing its rights shall prejudice or restrict the rights of that party, and no waiver of any such rights or of any breach of any contractual terms shall be deemed to be a waiver of any other right or of any later breach.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Severability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`If any provision of the Agreement is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Third party rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Except as expressly stated at clause 4.4, a person who is not party to the Agreement shall not have any rights under or in connection with them under the Contracts (Rights of Third Parties) Act 1999.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Arbitration and Governing Law`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The parties agree that:`}
            <ol type="a">
    <li>any dispute arising out of or in connection with this Agreement, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration under the LCIA Rules, which are deemed to be incorporated by reference into this clause;</li>
    <li>the number of arbitrators shall be one;</li>
    <li>the seat, or legal place, of arbitration shall be London; and</li>
    <li>the language to be used in the arbitral proceedings shall be English.</li>
            </ol>
          </li>
          <li parentName="ol">{`The governing law of this Agreement, including the arbitration agreement in clause 22.1(a), shall be the substantive law of England and Wales, and each party hereby submits to the exclusive jurisdiction of the English courts.`}</li>
        </ol>
      </li>
    </ol>
    <p><strong parentName="p">{`Schedule 1 – Restricted Industries`}</strong></p>
    <ul>
      <li parentName="ul">{`Alcohol`}</li>
      <li parentName="ul">{`Tobacco`}</li>
      <li parentName="ul">{`Healthcare, Pharmaceuticals (excluding general merchandise transactions of a drug store that maintains separate`}</li>
      <li parentName="ul">{`POS Systems for general merchandise and pharmacy)`}</li>
      <li parentName="ul">{`Financial Services`}</li>
      <li parentName="ul">{`Insurance Products`}</li>
      <li parentName="ul">{`Legal Services`}</li>
      <li parentName="ul">{`Political Organizations`}</li>
      <li parentName="ul">{`Court costs/alimony/support`}</li>
      <li parentName="ul">{`Fines, bail and bond payments`}</li>
      <li parentName="ul">{`Firearms`}</li>
      <li parentName="ul">{`Funeral services`}</li>
      <li parentName="ul">{`Dating or escort services`}</li>
      <li parentName="ul">{`Massage parlors`}</li>
      <li parentName="ul">{`Religious organizations`}</li>
      <li parentName="ul">{`Direct Marketing-Travel-Related Arrangement Services`}</li>
      <li parentName="ul">{`Direct Marketing-Outbound Telemarketing Merchants, Outbound telemarketers`}</li>
      <li parentName="ul">{`Direct Marketing-Inbound Telemarketing Merchants`}</li>
      <li parentName="ul">{`Direct Marketing - subscription merchants`}</li>
      <li parentName="ul">{`Infomercial merchants`}</li>
      <li parentName="ul">{`Gambling`}</li>
      <li parentName="ul">{`Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Race Tracks`}</li>
      <li parentName="ul">{`Online/Internet Gambling`}</li>
      <li parentName="ul">{`Drugs, Drug Proprietaries, Druggist Sundries`}</li>
      <li parentName="ul">{`Internet pharmacies`}</li>
      <li parentName="ul">{`Internet pharmacy referral sites`}</li>
      <li parentName="ul">{`Buyers clubs/membership clubs`}</li>
      <li parentName="ul">{`Credit counselling or credit repair services`}</li>
      <li parentName="ul">{`Credit protection/identity theft protection`}</li>
      <li parentName="ul">{`Multi-level marketing businesses`}</li>
      <li parentName="ul">{`Rebate-based businesses`}</li>
      <li parentName="ul">{`Up-Selling merchants`}</li>
      <li parentName="ul">{`Merchants that sell counterfeit or trademark infringing products or services`}</li>
      <li parentName="ul">{`Merchants that sell adult or mature content products and services`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      